* {
  font-family: Arial;
}

.header {
  margin: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}

.subheader {
  text-align: center;
  font-size: 18px;
}

.question {
  text-align: center;
  font-style: italic;
  font-size: 24px;
}

.button {
  display: inline-block;
  border-radius: 4px;
  padding: 1.5rem 0;
  width: 75%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  background: transparent;
  border: 2px solid lightgray;
  font-size: 18px;
}

.image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0 auto;
}

.input {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0 auto;
  margin-top: 30px;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid gray;
  font-size: 16px; /* prevent mobile zooming */
}

#endLink {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 24px;
}
